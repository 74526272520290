import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import { useExcelDownloder } from "react-xls";
import { Grid } from "@mui/material";
import { SmallContainedButton } from "../common/SmallContainedButton";

import { setVideoSheet, setLoadingVideoSheet } from "../../actions";
import { Loader } from "../common/Loader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function VideoDownloadSheet({
  showProducts,
  selectedProducts,
  openVideoDownloadSheet,
  setOpenVideoDownloadSheet,
  handleCloseVideoDownloadSheet,
}) {
  const dispatch = useDispatch();
  const selected_supplier = useSelector((state) => state.selected_supplier);
  const { ExcelDownloder, Type } = useExcelDownloder();
  const handleOpen = () => setOpenVideoDownloadSheet(true);
  const video_sheet = useSelector((state) => state.video_sheet);
  const loading_video_sheet = useSelector((state) => state.loading_video_sheet);
  const [videosheet, setVideoSheet] = useState([]);

  // useEffect(() => {
  //   if (openVideoDownloadSheet === true) {
  //     dispatch(setLoadingVideoSheet(true));
  //     fetch("https://api.saleprodev.com/customsheet")
  //       .then((response) => response.json())
  //       .then((response) => {
  //         let videosheet = [];
  //         response.map((item) => {
  //           let date = new Date(item.updatedAt);
  //           item.Date = date;
  //           videosheet.push(item);
  //         });
  //         setVideoSheet({ videosheet: videosheet });
  //         dispatch(setLoadingVideoSheet(false));
  //       });
  //   }
  // }, [openVideoDownloadSheet]);

  useEffect(() => {
    console.log("selectedProducts", selectedProducts);
    if (selectedProducts && selectedProducts.length !== 0) {
      let videosheet = [];
      let url;

      if (showProducts === "new") {
        if (selected_supplier === "bigbuy") {
          selectedProducts.map((item) => {
            url = `https://www.youtube.com/watch?v=${item.video}`;
            videosheet.push({ sku: item.sku, url: url });
          });
        }
      }

      if (showProducts === "imported" || showProducts === "exported") {
        if (selected_supplier === "aliexpress") {
          let filteredSelectedProducts = selectedProducts.filter(
            (item) =>
              item.aliexpress_product.result.aeop_a_e_multimedia !== undefined
          );

          filteredSelectedProducts.map((filteredItem) => {
            if (
              filteredItem.aliexpress_product &&
              filteredItem.aliexpress_product.result.aeop_a_e_multimedia &&
              Object.keys(filteredItem.aliexpress_product.result.aeop_a_e_multimedia
                .aeop_a_e_videos).length !== 0 &&
              filteredItem.aliexpress_product.result.aeop_a_e_multimedia
                .aeop_a_e_videos.aeop_ae_video &&
              filteredItem.aliexpress_product.result.aeop_a_e_multimedia
                .aeop_a_e_videos.aeop_ae_video.length !== 0
            ) {
              url = `https://video.aliexpress-media.com/play/u/ae_sg_item/${filteredItem.aliexpress_product.result.aeop_a_e_multimedia.aeop_a_e_videos.aeop_ae_video[0].ali_member_id}/p/1/e/6/t/10301/${filteredItem.aliexpress_product.result.aeop_a_e_multimedia.aeop_a_e_videos.aeop_ae_video[0].media_id}.mp4`;
              videosheet.push({
                product_id: filteredItem.product_id,
                url: url,
              });
            }
          });
        }

        if (selected_supplier === "bigbuy") {
          selectedProducts.map((item) => {
            url = item.images[item.images.length - 1]?.src;
            videosheet.push({ product_id: item.product_id, url: url });
          });
        }
      }

      setVideoSheet({ videosheet: videosheet });
    }
  }, []);

  return (
    <>
      <SmallContainedButton width="180px" onClick={handleOpen}>
        Download Video Sheet
      </SmallContainedButton>

      <Modal
        open={openVideoDownloadSheet}
        onClose={handleCloseVideoDownloadSheet}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading_video_sheet === false ? (
            <ExcelDownloder
              data={videosheet}
              filename={"videosheet"}
              type={"button"} // or type={'button'}
              style={{ marginLeft: "15%" }}
            >
              DOWNLOAD VIDEO SHEET
            </ExcelDownloder>
          ) : (
            <Grid container>
              <Loader size="30px" />
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
}
