import React, { useState, useEffect } from "react";
import moment from "moment";

import ControlledCheckbox from "./checkbox.js";
import no_image from "../assets/images/no_image.jpg";

import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

import { Card, Grid, Box, Tooltip, Typography } from "@mui/material";

export default function BigBuyProductsLayout({
  store,
  product,
  handleImport,
  selectedProducts,
  setSelectedProducts,
  fetchproducts,
}) {
  const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm:ss");

  const CalculatePrice = () => {
    let prices = [];
    if (product.has_variants) {
      product.has_variants.map((item) => {
        prices.push(parseFloat(item.price));
      });
      return `US$ ${Math.min(...prices)} - US$ ${Math.max(...prices)}`;
    }
  };

  const CalculateStock = () => {
    let prices = [];
    if (product.has_variants) {
      product.has_variants.map((item) => {
        prices.push(parseFloat(item.quantity));
      });
      return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }
  };

  const handleDownloadVideo = (videoName, videoURL) => {
    const link = document.createElement("a");
    link.download = videoName;
    link.href = videoURL;
    link.target = "_blank";
    link.click();
  };

  return (
    <Card sx={{ width: "100%", mt: 1 }}>
      <Grid
        container
        sx={{
          minHeight: "70px",
          display: "Grid",
          gridTemplateColumns: "2% 4% 35% 8% 8% 10% 8% 5% 5% 5% 6% 4%",
          alignItems: "center",
        }}
      >
        {/* Checkbox */}
        <Grid container justifyContent="center">
          <ControlledCheckbox
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            product={product}
          />
        </Grid>

        {/* Image */}
        <Grid container justifyContent="center" alignItems="center" p={0.5}>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  padding: 0,
                },
              },
            }}
            title={
              <img
                src={
                  product.images_data && product.images_data[0].url
                    ? product.images_data[0].url
                    : no_image
                }
                alt=""
                style={{
                  width: "250px",
                }}
              />
            }
          >
            <Grid
              item
              justifyContent="center"
              alignContent="center"
              sx={{
                minHeight: "60px",
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              <img
                src={
                  product.images_data && product.images_data[0].url
                    ? product.images_data[0].url
                    : no_image
                }
                alt=""
                width="60px"
              />
            </Grid>
          </Tooltip>
        </Grid>

        {/* Title & Sku # & Product ID */}
        <Grid container flexDirection="column" pl={1}>
          {product.name && product.name.length > 90 ? (
            <Tooltip arrow placement="top" title={product.name}>
              <Typography variant="body2">
                {`${product.name.slice(0, 90)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">{product.name}</Typography>
          )}

          <Grid container>
            {product.video && product.video !== "0" ? (
              <Tooltip arrow placement="top" title="Click to check the video">
                <VideocamIcon
                  fontSize="small"
                  color="success"
                  sx={{ cursor: "pointer", mr: 1 }}
                  onClick={() =>
                    handleDownloadVideo(
                      product.video,
                      `https://www.youtube.com/watch?v=${product.video}`
                    )
                  }
                />
              </Tooltip>
            ) : (
              <VideocamOffIcon
                fontSize="small"
                color="disabled"
                sx={{ mr: 1 }}
              />
            )}

            <Typography variant="body2" color="primary" sx={{ mr: 3 }}>
              {product.sku && `Sku #: ${product.sku}`}
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              component="a"
              target="_blank"
              // href={`https://www.bigbuy.eu/en/${product.url}.html`}
              href={`https://www.bigbuy.eu/search/result?q=${product.sku}`}
            >
              {product.id && `Product ID:  ${product.id}`}
            </Typography>
          </Grid>
        </Grid>

        {/* Barcode */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.ean13 === null || product.ean13 === ""
              ? "N/A"
              : product.ean13}
          </Typography>
        </Grid>

        {/* Weight */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.weight === null || product.weight === ""
              ? "N/A"
              : `${parseFloat(product.weight).toFixed(3)}kg`}
          </Typography>
        </Grid>

        {/* Cost */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            EUR{" "}
            {product.retailPrice && parseFloat(product.retailPrice).toFixed(2)}
          </Typography>
        </Grid>

        {/* Shipping Cost */}
        <Grid container flexDirection="column" alignItems="center">
          <Typography variant="body2">
            {product.shipping_cost ? product.shipping_cost : "N/A"}
          </Typography>
        </Grid>

        {/* Variation */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.variants && product.variants.length > 0
              ? product.variants[0].barcode
              : "N/A"}
          </Typography>
        </Grid>

        {/* Stock */}
        <Grid container justifyContent="center">
          <Typography variant="body2">
            {product.quantity}
          </Typography>
        </Grid>

        {/* Supplier */}
        <Grid container justifyContent="center">
          <Typography variant="body2" color="primary">
            BigBuy
          </Typography>
        </Grid>

        {/* Created At */}
        <Grid container justifyContent="center">
          <Typography variant="body2" textAlign="center">
            {formatDate(product.createdAt)}
          </Typography>
        </Grid>

        {/* <div style={{ textAlign: "center" }}>
          {product.import_status === null && "Not Imported"}
          {product.import_status === "imported" && "Imported"}
          {product.import_status === "exported" && "Exported"}
          {product.import_status === "deleted" && "Deleted"}
        </div> */}
        {
          // <div style={{textAlign:"center"}}>
          //   <Typography style={{color:"#5b5b5e"}}><Button variant="contained" size="small" onClick={(event) => handleImport(product)}>Import</Button></Typography>
          // </div>
        }
      </Grid>
    </Card>
  );
}
