import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  MenuItem,
  Select,
  Tooltip,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

export const SourcingAliexpressCarriersSelect = ({
  carrierList,
  productId,
}) => {
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [newCarrierList, setNewCarrierList] = useState([]);

  useEffect(() => {
    if (carrierList && carrierList.length > 0) {
      let carrierListRev = [];
      carrierList.map((item) => {
        let name = "unknown";
        if (item.service_name === "UBI") {
          name = "UBI";
        }
        if (item.service_name === "CAINIAO_STANDARD") {
          name = "AliExpress Standard Shipping";
        }

        if (item.service_name === "CAINIAO_PREMIUM") {
          name = "AliExpress Premium Shipping";
        }

        if (item.service_name === "YANWEN_AM") {
          name = "Special Line-YW";
        }
        if (item.service_name === "EMS_ZX_ZX_US") {
          name = "ePacket";
        }
        if (item.service_name === "DHL") {
          name = "DHL";
        }
        if (item.service_name === "USPS") {
          name = "USPS";
        }
        if (item.service_name === "UPS") {
          name = "UPS";
        }
        if (item.service_name === "UPSE") {
          name = "UPSE";
        }
        if (item.service_name === "EMS") {
          name = "EMS";
        }
        if (item.service_name === "E_EMS") {
          name = "E_EMS";
        }
        if (item.service_name === "FEDEX") {
          name = "FEDEX";
        }
        if (item.service_name === "FEDEX_IE") {
          name = "FEDEX_IE";
        }
        if (item.service_name === "Other") {
          name = "Seller's Shipping Method";
        }
        if (item.service_name === "SGP_OMP") {
          name = "4PX Singapore Post OM Pro";
        }
        if (item.service_name === "CAINIAO_STANDARD_SG") {
          name = "Cainiao Standard For Special Goods";
        }

        if (item.service_name === "AE_CN_SUPER_ECONOMY_G") {
          name = "AE CN SUPER ECONOMY G";
        }

        if (item.service_name === "CAINIAO_FULFILLMENT_SECO_G") {
          name = "Aliexpress Selection Super Economy Global";
        }

        if (item.service_name === "CAINIAO_FULFILLMENT_STD") {
          name = "AliExpress Selection Standard";
        }

        if (item.service_name === "CAINIAO_FULFILLMENT_SECO_SG") {
          name = "Aliexpress Selection Economy For Special Goods";
        }
        if (item.service_name === "AUSPOST") {
          name = "AUSPOST ";
        }

        if (name === "unknown") {
          carrierListRev.push({
            name: name + "-" + item.service_name,
            value: item.service_name,
            amount: `$${item.freight.amount}`,
            currency: item.freight.currency_code,
            duration: item.estimated_delivery_time,
          });
        } else {
          carrierListRev.push({
            name: name,
            value: item.service_name,
            amount: `$${item.freight.amount}`,
            currency: item.freight.currency_code,
            duration: item.estimated_delivery_time,
          });
        }
      });

      setNewCarrierList(carrierListRev);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedCarrier(event.target.value);
    console.log("selectedCarrier", event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-select-small">Choose Carrierr</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={selectedCarrier}
        label="Choose Carrierrrrr"
        onChange={handleChange}
        size="small"
        sx={{
          height: 30,
          fontSize: "0.8rem",
        }}
      >
        {newCarrierList.map((carrierObj, index) => (
          <MenuItem key={index} value={carrierObj}>
            <Grid container justifyContent="space-between">
              <Grid item xs={8}>
                {carrierObj.name.length > 16 ? (
                  <Grid container>
                    <Tooltip arrow placement="top" title={carrierObj.name}>
                      <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                        {carrierObj.name.slice(0, 16) + " ..."}
                      </Typography>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                    {carrierObj.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  align="right"
                  variant="body2"
                  sx={{ fontSize: "0.8rem" }}
                >
                  {carrierObj.amount === "$0"
                    ? "Free"
                    : carrierObj.amount}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};